<script setup>

    //UploadSummary
    //Data validation of uploaded address list

    // Imports ----
    import { defineProps, ref, defineEmits, computed } from 'vue';
    import UploadErrors from './UploadErrors';

    // Props ----
    const props = defineProps({
        uploadedListSummary: {
            type: Object,
            required: true
        }
    });

    const editingMode = ref(false);

    const recipientsSelectTypeSelected = ref("proceed");
    const recipientsSelectType = [
        { value: 'proceed', label: 'Proceed only with records loaded successfully' },
        { value: 'reupload', label: 'Cancel and Upload Again' },
        { value: 'fix', label: 'Fix Records with Errors' }
    ];


    const hasUploadErrors = computed(() => {
        return props.uploadedListSummary.uploadedListItems?.filter(addr => addr.validationErrors.length > 0).length > 0
    });

    function goToNext() {

        if (recipientsSelectTypeSelected.value == "proceed")
            emits('next');
        else if (recipientsSelectTypeSelected.value == "fix")
            editingMode.value = true;
        else
            emits('cancel');

    }

    function cancelEditing() {
        editingMode.value = false;
    }

    // TODO: refresh this page
    function updated(addresses) {
        recipientsSelectTypeSelected.value = "proceed"
        editingMode.value = false;
        emits('update', addresses);
    }
    function showOption(option) {
        if (option == "fix" && !hasUploadErrors.value)
            return false;
        return true;
    }


    // Emits ----
    const emits = defineEmits(['next', 'cancel', 'update']);

</script>

<template>
    <div class="upload-summary">

        <div class="row my-5">

            <div class="col border-bottom border-color-1">

                <div class="d-flex">
                    <div class="text-color-1">
                        <h2 v-if="!editingMode && !hasUploadErrors" class="mb-0"> Upload Address Summary</h2>
                        <h2 class="mb-0" v-else> Edit Failed Records</h2>
                    </div>
                </div>

            </div>

        </div>

        <div v-if="editingMode && hasUploadErrors">
            <UploadErrors :uploadedListSummary="props.uploadedListSummary" :editingMode="editingMode" @update="updated" @cancel="cancelEditing"></UploadErrors>
        </div>

        <div class="row mb-5 mt-2" v-else>
            <div class="col">

                <div class="row records-summary">
                    <div class="col-sm-4">
                        <h4><span>{{ props.uploadedListSummary.total }}</span> <span class="fw-normal">Total Records</span></h4>
                    </div>
                    <div class="col-sm-4">
                        <h4><span class="text-danger"> {{ props.uploadedListSummary.failed }}</span> <span class="fw-normal"> Records Failed </span></h4>
                    </div>
                    <div class="col-sm-4">
                        <h4><span class="text-success"> {{  props.uploadedListSummary.success }}</span><span class="fw-normal"> Records Loaded Successfully </span></h4>
                    </div>
                </div>

                <div class="row mb-5 my-5 action-container">
                    <div class="mb-2">
                        <h3 class="text-center">Preview the records that failed below. How would you like to proceed?</h3>
                    </div>

                    <div class="col-sm-4 offset-sm-4">

                        <div class="form-check mb-3" v-for="type in recipientsSelectType" :key="type.value">
                            <label class="form-check-label" v-if="showOption(type.value)">
                                <input class="form-check-input" type="radio" v-model="recipientsSelectTypeSelected" :value="type.value">
                                {{type.label}}
                            </label>
                        </div>

                    </div>

                </div>

                <div class="row my-5">
                    <div class="col text-center">
                        <button class="btn btn-outline-primary  me-3" @click="emits('cancel');">Cancel</button>
                        <button class="btn btn-primary" @click="goToNext()">Next</button>
                    </div>

                </div>

                <UploadErrors :uploadedListSummary="props.uploadedListSummary" :editingMode="editingMode" @update="updated" @cancel="cancelEditing" v-if="hasUploadErrors"></UploadErrors>

            </div>
        </div>

    </div>
</template>

<style lang="scss">

    .upload-summary {

        .records-summary {
            background: #f2fcfc;
            padding: 10px;
        }

        .n-action-button {
            .bi {
                color: #ffffff;
            }
        }

        .action-container {
            background: #f2fcfc;
            padding: 1rem;
        }
    }
</style>