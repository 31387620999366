<script setup>
    // Imports ----
    import { defineEmits, computed } from 'vue';
    import { store } from '@/Store';

    import AddressReviewTable from '@/site/areas/cart/components/AddressReviewTable';

    // Emits ----
    defineEmits(['update']);

    const addressCount = computed(() => store.cart.current.shipToList.length || 0)

</script>

<template>
    <div class="address-list">
        <h3 v-if="addressCount > 1" class="p-4 ">Finalize shipping addresses and quantities</h3>
        <h3 v-else class="p-4 ">Shipping Address</h3>
        <MessageList :messages="store.cart.current.messages" :add-box="false" class="alert alert-danger" />

        <AddressReviewTable />
    </div>
</template>

<style lang="scss">
    .address-list {
        .list-group-item:nth-child(even) {
            background: $neutral-lt;
        }
    }
</style>