<script setup>

// CheckoutPage
// Allows the user to view and change the shipping information for the cart.

// Components ----
import CartBodyPanel from './components/CartBodyPanel';
import ShipTo from './components/ShipTo';

// Imports ----
import { store } from '@/Store';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const continueStatus = ref(false);

const router = useRouter();

function checkContinueStatus(type) {
    if (type.addValue === 'SAVED') {
        type.addressId ? (continueStatus.value = true) : (continueStatus.value = false);
    } else {
        type.formIsValid ? (continueStatus.value = true) : (continueStatus.value = false);
    }
}

function review() {
    router.push({ name: 'Review' });
}

</script>

<template>
    <PageTemplate>
        <div class="container root-page checkout-page">

            <div class="float-end">
                <PageButton class="me-2" :outline="true" url="/cart">Back to Cart</PageButton>
                <button class="btn btn-color-1" v-if="!store.cart.isMultiShip()" @click="review"
                    :disabled="!continueStatus">
                    Continue
                </button>
            </div>

            <h1 class="cart-page-header mb-3">Checkout <span class="text-muted">/ Shipping</span></h1>

            <div class="row">
                <div class="col-12 col-md-9">
                    <CartBodyPanel class="cart-body-panel">
                        <template #title>
                            <h2>Ship To</h2>
                        </template>

                        <ShipTo :editable="true" :editing="true" @addressType="checkContinueStatus" />

                    </CartBodyPanel>
                </div>

                <div class="col-12 col-md-3">
                    <CartBodyPanel class="cart-body-panel ship-method-panel">
                        <template #title>
                            <h2>Ship Method</h2>
                        </template>

                        <ShipMethodSelector v-model="store.cart.current.shipMethodCode" />
                    </CartBodyPanel>
                </div>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
.root-page {
    min-height: 30rem;
}

.checkout-page {
    .n-body-panel {
        .body {
            padding: 0.75rem 0rem;
        }
    }

    .ship-method-panel {
        .body {
            padding-left: 1.5rem;
        }
    }
}
</style>