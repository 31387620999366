
// SignUpAutoForm
// Implements the form that signs a user in.

// Imports ----
import SignUpForm from './SignUpForm';

export default class SignUpAutoForm extends SignUpForm {

    // Constructor
    constructor() {
        // Pass our model to the base class
        super({
            automotiveType: '',       // TODO: SXM-specific. New subclass perhaps?
        });

        // Set required fields
        this.validator.fields.automotiveType.isRequired = true;
        this.validate();

        this.automotiveTypes = [
            { id: 'Auction Partner', text: 'Auction' },
            { id: 'Affinity/Shops Partner', text: 'Affinity/Shops' },
            { id: 'Franchise Dealer', text: 'Franchise Dealer' },
            { id: 'Independent Dealer', text: 'Independent Dealer' },
            { id: 'Lot Service Partner', text: 'Lot Service' }
        ];
    }

    onValidate() {
        super.onValidate();
    }

    async onValidateAsync() {
        super.onValidateAsync();
    }

    async onSubmit() {
        return await super.onSubmit();
    }
}
