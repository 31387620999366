
// main.js
// Main entry point for the site

// Imports ----
import App from './App.vue';
import { createApp } from 'vue';
import { store } from './Store';

import VueSnip from 'vue-snip';
import 'bootstrap';

// Create the router
const router = store.createVueRouter();

// Set up snip options
const snipOptions = {
    snipMethod: 'css'
};

// Create the app
const app = createApp(App);

app.use(router);
app.use(VueSnip, snipOptions);

// This allows the use of a dynamic "v-auto-focus" attribute on an element to give it focus once loaded.
// It can be used with boolean literal (e.g. <input v-auto-focus="true">) or a dynamic value (e.g. <input v-auto-focus="props.autoFocus">)
// TODO: Look for a way to fold this into the Nucleus layer. Perhaps a setup similar to router.js.
app.directive('auto-focus', (el, binding) => {
    if (el.wasFocused === true) return;     // Prevents certain situation where focus wants to return again, probably due to this also handling 'updated' as well as 'mounted'

    // eslint-disable-next-line
    if ((!binding.hasOwnProperty('value')) || binding.value) {
        el.focus();
        el.wasFocused = true;
    }
});

// Initialize the custom store singleton and pass it the created Vue app
store.setupVue(app);

app.mount('#app');