<script setup>

    // ShipToList
    // Allows the user to select multiple contacts as the ship-to, generating a bulk order.

    // Components ----


    // Constants ----
    const Display = {
        SUMMARY_ADDRESS_LIST: 'SUMMARY_ADDRESS_LIST',
        PREVIEW_ADDRESS_LIST: 'PREVIEW_ADDRESS_LIST',
        CONFIRM_ADDRESS_LIST: 'CONFIRM_ADDRESS_LIST',
        UPLOAD_ADDRESS_LIST: 'UPLOAD_ADDRESS_LIST'
    };

    const router = useRouter();

    const AddOption =
    {
        NEW_CONTACT: 'NEW_CONTACT',
        FROM_CONTACTS: 'FROM_CONTACTS',
        UPLOAD_FILE: 'UPLOAD_FILE'
    };

    import PrepareMailList from '@/site/areas/cart/components/PrepareMailList.vue';
    import UploadAddressList from '@/site/areas/cart/components/UploadAddressList';
    import PreviewAddressList from '@/site/areas/cart/components/PreviewAddressList';
    import ConfirmAddressList from '@/site/areas/cart/components/ConfirmAddressList';
    import UploadSummary from '@/site/areas/cart/components/UploadSummary';


    // Import ----
    import { ref, computed, reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import { store } from '@/Store';
    import ShipToUserForm from '../forms/ShipToUserForm';

    // State ----
    const display = ref(Display.UPLOAD_ADDRESS_LIST);
    const selectedOption = ref(AddOption.UPLOAD_FILE);
    const uploadedListSummary = ref({});
    const form = reactive(new ShipToUserForm());

    let showUploadFile = ref(false);
    const hasFileUploaded = ref(false);
    //computed



    const validAddresses = computed(() => {
        return uploadedListSummary.value.uploadedListItems?.filter(addr => addr.validationErrors.length == 0).map((addr) => { return addr.address });
    })

    const haveAddressesUpdated = ref(false);

    // Handlers ----

    function onPreviewClicked() {
        display.value = Display.PREVIEW_ADDRESS_LIST;
    }

    function onConfirmClicked() {
        // Pass only valid addresses
        display.value = Display.CONFIRM_ADDRESS_LIST;
        // upload the address here
        updatedAddresses(validAddresses.value);
    }

    function onSummaryClicked() {
        display.value = Display.SUMMARY_ADDRESS_LIST;
    }

    function showPreviewList(uploadedAddresses) {
        uploadedListSummary.value = uploadedAddresses;
    }

    function updatedAddresses(updatedAddresses) {
        haveAddressesUpdated.value = true;
        store.cart.setShipToMultiplContacts(updatedAddresses);
    }


    async function goToReviewPage(shipToContacts) {
        if (!haveAddressesUpdated.value) {
            await store.cart.setShipToMultiplContacts(shipToContacts);
        }

        if (shipToContacts.length == 1) {
            await updateToSingleContact(shipToContacts);
            router.push({ name: 'Review' });
            return;
        }

        if (await store.cart.updateMultiShipItemsQuantity()) {
            router.push({ name: 'Review' });
        }

    }

    async function updateToSingleContact(shipToContacts) {
        const shipTo = shipToContacts[0];
        form.model.addressee = shipTo.addressee;
        form.model.companyName = shipTo.companyName;
        form.model.addressLine1 = shipTo.addressLine1;
        form.model.addressLine2 = shipTo.addressLine2;
        form.model.addressLine3 = shipTo.addressLine3;
        form.model.addressLine4 = shipTo.addressLine4;
        form.model.city = shipTo.city;
        form.model.state = shipTo.state;
        form.model.postalCode = shipTo.postalCode;
        form.model.countryCode = shipTo.countryCode;
        await store.cart.setShipToModeToSingleContact()
        await form.submit(false);
    }

    function goToUploadFile() {
        display.value = Display.UPLOAD_ADDRESS_LIST;
        selectedOption.value = AddOption.UPLOAD_FILE;
        showUploadFile.value = true;
        uploadedListSummary.value = {};
    }

    function fixedAddresses(addresses) {
        uploadedListSummary.value = addresses;
        onSummaryClicked();
    }

    function uploadStatus(uploadStatus) {
        if (uploadStatus)
            hasFileUploaded.value = true;
        else
            hasFileUploaded.value = false;
    }

</script>

<template>
    <div class="ship-to-contacts">
        <!-- Option to upload file and proceed -->
        <template v-if="display === Display.PREVIEW_ADDRESS_LIST">
            <PreviewAddressList :uploadedListSummary="uploadedListSummary" @back="goToUploadFile" @next="onSummaryClicked">
                <template #empty>
                    No addresses found. Please go back and upload addresses.
                </template>
            </PreviewAddressList>

        </template>

        <template v-else-if="display === Display.SUMMARY_ADDRESS_LIST">
            <UploadSummary :uploadedListSummary="uploadedListSummary" @next="onConfirmClicked" @cancel="goToUploadFile" @update="fixedAddresses">
                <template #empty>
                    Empty Addresses
                </template>
            </UploadSummary>

        </template>

        <template v-else-if="display === Display.CONFIRM_ADDRESS_LIST">
            <ConfirmAddressList :addressList="validAddresses" @update="updatedAddresses">
                <template #empty>
                    Empty Addresses
                </template>
            </ConfirmAddressList>

            <div class="show-right">
                <PageButton class="me-2" :outline="true" @click="goToUploadFile">Cancel</PageButton>
                <PageButton class="me-2" :disabled="validAddresses.length === 0" @click="goToReviewPage(validAddresses)">Review</PageButton>
            </div>
        </template>


        <template v-else>
            <div class="row my-5">
                <div class="col border-bottom border-color-1">

                    <div class="d-flex">
                        <div class="text-color-1">
                            <h2 class="mb-0">Upload Your List of Addresses</h2>
                        </div>
                    </div>

                </div>
            </div>

            <UploadAddressList @uploadedAddressList="showPreviewList" @uploaded="uploadStatus" />


            <div class="row mb-5">
                <div class="text-center mt-4 mb-4">

                    <div class="show-right">
                        <PageButton class="me-2" :disabled="!hasFileUploaded" @click="onPreviewClicked">Preview</PageButton>
                    </div>

                </div>
            </div>

            <div class="row mb-5">
                <PrepareMailList />
            </div>
        </template>
    </div>
</template>

<style lang="scss">

    .ship-to-contacts {
        .add-contacts {
            .sub-title {
                font-weight: $bold;
            }
        }

        .show-right {
            float: right
        }


        .add-options {
        }

        .add-option {
            border: 1px solid $gray-20;
            padding: 0.5rem;
            height: 10rem;
        }
    }
</style>