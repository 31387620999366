export const UserRoles = [
    {
        "id": "SXM.AffinityShopsFieldTeam",
        "name": "Affinity/Shops Field Team",
    },
    {
        "id": "Dealer.AffinityShopsPartners",
        "name": "Affinity/Shops Partners",
    },
    {
        "id": "Dealer.AuctionPartners",
        "name": "Auction Partners",
    },
    {
        "id": "Dealer.FranchiseDealers",
        "name": "Franchise Dealers",
    },
    {
        "id": "Dealer.IndependentDealers",
        "name": "Independent Dealers",
    },
    {
        "id": "Dealer.LotServicePartners",
        "name": "Lot Service Partners",
    },
    {
        "id": "SXM.OemAccountTeam",
        "name": "OEM Account Team",
    },
    {
        "id": "SXM.OemArdFieldTeam",
        "name": "OEM/ARD Field Team",
    },
    {
        "id": "SXM.PreOwnedAccountTeam",
        "name": "Pre-Owned Account Team",
    },
    {
        "id": "Qualfon.Csd",
        "name": "Qualfon CSD",
    },
    {
        "id": "Qualfon.CsdAdmin",
        "name": "Qualfon CSD Admin",
    },
    {
        "id": "Qualfon.Support",
        "name": "Qualfon Support",
    },
    {
        "id": "SXM.RefreshFieldTeam",
        "name": "Refresh Field Team",
    },
    {
        "id": "SXM.RetailSalesTeam",
        "name": "Retail Sales Team",
    },
    {
        "id": "Retailer.Retailers",
        "name": "Retailers",
    },
    {
        "id": "Client.Admin",
        "name": "SiriusXM Admin",
    },
    {
        "id": "SA",
        "name": "System Administrator",
    }
];

// Admin roles
export const UserRolesWithAdminAccess = [
    {
        "id": "Qualfon.Csd",
        "name": "Qualfon CSD",
    },
    {
        "id": "Qualfon.CsdAdmin",
        "name": "Qualfon CSD Admin",
    },
    {
        "id": "Client.Admin",
        "name": "SiriusXM Admin",
    },
    {
        "id": "SA",
        "name": "System Administrator",
    }
];

// division
export const Divisions = [
    { id: 'Automotive', text: 'Automotive' },
    { id: 'OEM', text: 'OEM' },
    { id: 'Retail', text: 'Retail' }
];

// user statuses
export const UserStatuses = [
    { id: 'Active', text: 'Active' },
    { id: 'Inactive', text: 'Inactive' },
    { id: 'On Leave', text: 'On Leave' }
];

// TODO: This needs to be fetched from API
export const LimitType = {
    none: 0,
    monthly: 3 
}