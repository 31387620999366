<script setup>

    // SignUpAutoPage
    // Primary sign-in or sign-up page for automotive partners.
    // TODO: Responsiveness

    // Components ----
    import SignInPageTemplate from '@/template/SignInPageTemplate';
    import SignInForm from './components/SignInForm';
    import SignUpAutoForm from './components/SignUpAutoForm';

    // Imports ----
    import { ref } from 'vue';

    // State ----
    const createAccount = ref(false);

    // Handlers ----
    function onCreateAccount() {
        createAccount.value = true;
    }
</script>

<template>
    <SignInPageTemplate>
        <div class="container page-container">

            <div class="row">
                <div class="col col-12 col-sm-6 col-md-5 offset-md-1 col-lg-4 offset-lg-1">
                    <div class="create-account-header text-center mt-5 mb-3">
                        <h1>Account Sign In</h1>
                    </div>

                    <div class="alert alert-info">
                        Welcome to our new eStore. Please set up a new account to continue to access SiriusXM merchandise.
                    </div>

                    <SignInForm />
                </div>
                
                <div class="col col-12 col-sm-6 col-md-5 col-lg-4 offset-lg-1">
                    <div class="create-account-header text-center mt-5 mb-3">
                        <h1>Create an Automotive Account</h1>
                    </div>

                    <template v-if="createAccount">
                        <SignUpAutoForm />
                        <div class="footer-spacer"></div>
                    </template>

                    <div v-else>
                        <div class="d-grid mt-3">
                            <button class="btn btn-outline-color-1" @click="onCreateAccount">CREATE ACCOUNT</button>
                        </div>
                    </div>
                </div>
                <div class="col"></div>
            </div>

        </div>
    </SignInPageTemplate>
</template>

<style lang="scss">

    .page-container
    {
        margin-bottom: 4rem;
    }

    .create-account-header {
        height: 3rem;

        h1 {
            color: $black;
            font-weight: $normal;
            font-size: 1.2rem;
        }
    }

    .footer-spacer {
        height: 4rem;
    }

</style>