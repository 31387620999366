<script setup>

    // ContactUsPanel
    // Shows the contact us page and form.

    // Components ----

    // Imports ----
    import { reactive, ref, watch } from 'vue';
    import { store } from '@/Store';
    import ContactUsForm from '../forms/ContactUsForm.js';

    // State ----
    const form = reactive(new ContactUsForm());
    const sent = ref(false);
    const resetForm = ref(function () {
        sent.value = false;

        form.model.topic = '';
        form.model.name = '';
        form.model.email = '';
        form.model.phone = '';
        form.model.orderNumber = '';
        form.model.message = '';

        getDetails();
    });

    // Handlers ----
    store.onInitialized(() => {
        getDetails();
    })

    async function onSubmit() {
        sent.value = true;
        await form.submit();
    }

    function getDetails() {
        form.model.name = store.user.firstName + ' ' + store.user.lastName;
        form.model.email = store.user.email;
    }


    watch(
        form.model,
        () => {
            if (form.model.topic == 'Order Status/Tracking') {
                form.validator.fields.orderNumber.isRequired = true;
                form.validate();
            } else {
                form.validator.fields.orderNumber.isRequired = false;
                form.validate();
            }
        },
        { deep: true }
    );

</script>

<template>
    <div class="help-panel">

        <template v-if="sent">
            <div class="alert alert-success">
                Thank you, your request has been received. If you require additional assistance, please send us an <a href="mailto:sxmsupport@qualfon.com">email</a>. Hours of operation: 8:30 AM - 5:00 PM ET Mon-Fri.
            </div>
            <div class="row mt-5">
                <div class="col col-3">
                    <router-link to="/" class="btn btn-color-1">RETURN TO HOME PAGE</router-link>
                </div>
                <div class="col col-3">
                    <button class="btn btn-color-1" @click="resetForm">SEND ANOTHER MESSAGE</button>
                </div> 
            </div>
        </template>

        <template v-else>
            <div class="mb-4">
                If you are unable to find the information you need after visiting <a target="_blank" href="/pdf/SiriusXM_FAQs.pdf">Frequently Asked Questions</a>, please use the contact form below.
            </div>

            <FormContainer :form="form" optional-text="(Optional)">

                <div class="row">
                    <div class="col-sm-6 col-md-4">
                        <div class="mb-3">
                            <FieldLabel field-name="topic" label="Choose a Topic">
                                <DropDownListField v-model="form.model.topic" :options="form.topics" null-option-text="Select a Topic" />
                            </FieldLabel>
                        </div>
                        <div class="mb-3">
                            <FieldLabel field-name="name" label="Name">
                                <TextBoxField v-model="form.model.name" :disabled="true" />
                            </FieldLabel>
                        </div>
                        <div class="mb-3">
                            <FieldLabel field-name="email" label="Email">
                                <TextBoxField v-model="form.model.email" placeholder="Email Address" />
                                <FieldMessages />
                            </FieldLabel>
                        </div>
                        <div class="mb-3">
                            <FieldLabel field-name="phone" label="Phone Number">
                                <TextBoxField v-model="form.model.phone" placeholder="Phone Number" :is-phone-number="true" />
                                <FieldMessages />
                            </FieldLabel>
                        </div>
                        <div class="mb-3">
                            <FieldLabel field-name="orderNumber" label="Order Number">
                                <TextBoxField v-model="form.model.orderNumber" placeholder="Order Number" />
                            </FieldLabel>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <FieldLabel field-name="message" label="Message">
                            <TextAreaField v-model="form.model.message" />
                        </FieldLabel>
                    </div>
                </div>

                <div class="row mt-4 mb-5">
                    <div class="col col-3 d-grid">
                        <router-link to="/" class="btn btn-outline-color-1">CANCEL</router-link>
                    </div>
                    <div class="col col-3 d-grid">
                        <button class="btn btn-color-1" @click="onSubmit" :disabled="form.status.isInvalid">SUBMIT</button>
                    </div>
                </div>

            </FormContainer>
        </template>
    </div>
</template>

<style lang="scss">


</style>