<script setup>

    // AccountPage
    // Shows various pages related to the user's account.

    // Components ----
    import ProfilePanel from './components/ProfilePanel';
    import FavoritesPanel from './components/FavoritesPanel';
    import ContactsPanel from './components/ContactsPanel';
    import OrderHistoryPanel from './components/OrderHistoryPanel';
    import PasswordPanel from './components/PasswordPanel';

    // Imports ----
    import { ref, watch, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { store } from '@/Store';

    const route = useRoute();
    const router = useRouter();

    // State ----
    let section = ref(route.params.section);

    // Update section when route (URL) changes
    watch(() => route.params, () => { section.value = route.params.section });

    // Computed
    const isImpersonating = computed(() => {
        return store.user.isImpersonating;
    })
    

    // Methods ----
    function getLinkClass(forSection) {
        return { 'active': forSection === section.value };
    }

    async function onSignOut() {
        await store.signOut(router);
    }

    // TODO: Not everyone will be able to change their password, depending on their auth method and/or store settings

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <div class="row">
                <div class="col-sm-4 col-md-3 d-none d-sm-block">
                    <div class="top-link side-link">My Account</div>
                    <div class="sub-link side-link" :class="getLinkClass('profile')"><router-link to="profile">My Profile</router-link></div>
                    <div class="sub-link side-link" :class="getLinkClass('favorites')"><router-link to="favorites">My Favorites</router-link></div>
                    <div class="sub-link side-link" :class="getLinkClass('contacts')"><router-link to="contacts">My Contacts</router-link></div>
                    <div class="sub-link side-link" :class="getLinkClass('order-history')"><router-link to="order-history">My Order History</router-link></div>
                    <div class="sub-link side-link" :class="getLinkClass('password')"><router-link to="password">My Password</router-link></div>

                    <ShowOnlyForRoles :roles="['SXM.OemAccountTeam', 'SXM.OemArdFieldTeam', 'SXM.RefreshFieldTeam', 'SXM.AffinityShopsFieldTeam', 'SXM.PreOwnedAccountTeam', 'SXM.RetailSalesTeam', 'Qualfon.CsdAdmin', 'Qualfon.Support']">
                        <div class="sub-link side-link" :class="getLinkClass('')"><a target="_blank" href="/pdf/SXM Conference Orders form_v1.5.pdf">Event Order Form</a></div>
                    </ShowOnlyForRoles>

                    <div v-if="!isImpersonating" class="sub-link side-link" :class="getLinkClass('password')"><a class="dropdown-item" href="#" @click.cancel="onSignOut">Sign Out</a></div>


                </div>
        <div class="col">

            <div v-if="section === 'profile'">
                <!-- My Profile -->
                <ProfilePanel />
            </div>

            <div v-if="section === 'favorites'">
                <!-- My Favorites -->
                <FavoritesPanel />
            </div>

            <div v-if="section === 'contacts'">
                <!-- My Contacts -->
                <ContactsPanel />
            </div>

            <div v-if="section === 'order-history'">
                <!-- My Order History -->
                <OrderHistoryPanel />
            </div>

            <div v-if="section == 'password'">
                <!-- My Password -->
                <PasswordPanel />
            </div>
        </div>
        </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .side-link {
        padding-top: 0.5rem;
        
        &.active {
            font-weight: $bold;
        }

        a, a:visited {
            color: $color-2;
            text-decoration: none;
        }
    }

    .top-link {
        padding-top: 0rem;
    }

    .sub-link {
        padding-left: 1rem;
    }
</style>