<script setup>
    // Imports ----
    import { defineProps, reactive, computed } from 'vue';
    import { store } from '@/Store';
    import { Util, AddressBlock } from '@dd-nucleus/nucleus-vue';

    defineProps({
        readonly: {
            type: Boolean,
            default: false,
        }
    });

    // States ----
    const quantityMap = reactive({ item: {} });
    const selections = reactive({ addresses: {} });


    // Computed ----
    // const cart = ref(store.cart.current);
    const items = computed(() => {
        return store.cart.current.items.sort((a, b) => a.id < b.id ? -1 : 1)
    });

    const shipToList = computed(() => {
        return store.cart.current.shipToList.map((address) => {
            return {
                ...address,
                orderContactItemQuantities: address.orderContactItemQuantities.sort((a, b) => a.cartItemId < b.cartItemId ? -1 : 1)
            };
        });
    });

    const allSelected = computed(() => {
        return store.cart.current.shipToList.length === Object.keys(selections.addresses).length;
    });

    function updateQuantityMap(itemId, quantity) {
        quantityMap.item[itemId] = parseInt(quantity);
    }

    function applyQuantityToAllItems() {
        for (let address of store.cart.current.shipToList) {
            for (let item of address.orderContactItemQuantities) {
                if (quantityMap.item[item.cartItemId]) {
                    item.quantity = quantityMap.item[item.cartItemId];
                }
            }
        }
    }

    function toggleAddressSelect(addressId) {
        // if the item is already present remove it
        if (selections.addresses[addressId]) {
            delete selections.addresses[addressId];
        } else {
            selections.addresses[addressId] = true;
        }
    }

    function onRemoveSelected() {
        for (let address of store.cart.current.shipToList) {
            if (selections.addresses[address.id]) {
                address.selectionIsMarkedForRemoval = true;
            }
        }

        const addressIds = Object.keys(selections.addresses);
        store.cart.removeMultiShipAddresses(addressIds);
        selections.addresses = {};
    }

    function toggleSelectAll() {
        if (allSelected.value) {
            selections.addresses = {};
        } else {
            markAllAddressAsSeelcted();
        }
    }

    function markAllAddressAsSeelcted() {
        for (let address of store.cart.current.shipToList) {
            selections.addresses[address.id] = true;
        }
    }

    function getAddressee(address) {
        if (Util.isNotEmpty(address.addressee)) {
            return address.addressee;
        } else {
            return address.firstName + " " + address.lastName;
        }
    }
    function formatAddress(address) {
        const state = address.state ?? address.province;
        let addressArray = [address.addressLine1, address.addressLine2, address.city, state, address.postalCode, address.countryCode];
        return addressArray.join(', ').replaceAll(/, , /g, ', ');
    }

</script>

<template>
    <div>
        <div v-if="shipToList?.length == 1" class="col-3">
            <div class="n-address-tile d-flex flex-column border">
                <div class="name-block">
                    <div v-if="shipToList[0]?.shipToEmail?.length > 0" class="email">
                        <a :href="'mailto:' + shipToList[0].shipToEmail">{{shipToList[0].shipToEmail }}</a>
                    </div>
                </div>
                <div class="flex-fill n-address">
                    <AddressBlock :address="shipToList[0]" :height="100" />
                </div>
            </div>
        </div>

        <table class="table table-striped multishipping-table" v-if="!!store.cart.current && shipToList?.length > 1">

            <thead class="multishipping-table__header">

                <tr>

                    <th class="item__address sticky-col">
                        <div>Select<br/> All</div>
                        <div>
                            <input v-if="!readonly" type="checkbox" class="item__checkbox form-check-input" @click="toggleSelectAll" :checked="allSelected" />
                            <button v-if="!readonly" class="btn btn-success ms-2" @click="onRemoveSelected">
                                Remove Selected
                            </button>
                        </div>
                       
                    </th>
                    <th class="item__count" v-for="item in items" :key="item.id">
                        <!-- TODO: add a tooltip to show product name -->
                        {{ item.product.itemNumber }}
                        <input v-if="!readonly" type="number" class="form-control" min="1" @change="(e) => updateQuantityMap(item.id, e.target.value)" />
                    </th>
                    <th>
                        <button v-if="!readonly" class="btn btn-success" @click="applyQuantityToAllItems">
                            Apply
                        </button>
                    </th>
                </tr>
            </thead>

            <tbody class="multishipping-table__body">
                <tr v-for="address in shipToList" :key="address.id">
                    <td class="sticky-col">
                        <div class="d-flex">
                            <div class="me-4">
                                <input v-if="!readonly"
                                       type="checkbox"
                                       class="item__checkbox form-check-input"
                                       @click="() => toggleAddressSelect(address.id)"
                                       :checked="selections.addresses[address.id] === true" />
                            </div>
                            <div>
                                <div><b>{{ address.companyName }} </b></div>
                                <div><b>{{ getAddressee(address) }}</b>, {{ formatAddress(address)}} </div>
                            </div>

                        </div>
                    </td>
                    <td v-for="item in address.orderContactItemQuantities" :key="item.id">
                        <input v-if="!readonly" type="number" min="1" class="form-control" v-model.number="item.quantity" required />
                        <p v-else>{{ item.quantity }}</p>
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss">
    // address table style
    .multishipping-table {
        max-height: 600px;
        max-width: 100%;
        overflow: scroll;
        display: block;
    }

    .multishipping-table__header th {
        padding: 3px;
        position: sticky;
        top: 0;
        z-index: 1;
        background: white !important;
    }

    .multishipping-table__body .sticky-col {
        position: sticky;
        left: 0;
        z-index: 2;
        background: white !important;
    }

    .multishipping-table__header .sticky-col {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 3;
    }


    .item__checkbox {
        width: 1.5rem !important;
        height: 1.5rem !important;
    }

    .item__address {
        min-width: 470px;
        width: 100%;
    }

    .item__count {
        min-width: 80px;
        width: 100%;
    }

    input:invalid {
        border: 1px solid red;
    }

    input:valid {
        border: 1px solid green;
    }
</style>